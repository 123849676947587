import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { role_list } from "../redux/api/dummyJson";
import { toggle_off, toggle_on } from "../../assets/imgs";
import apiRequest from "../redux/api/apiRequest";
import {
  useLazyPermissionsListQuery,
  useAddRoleMutation,
  useLazyViewRoleQuery,
  useUpdateRoleMutation,
} from "../redux/api/api";
import { toast } from "react-toastify";

const AddRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const [permissionsList] = useLazyPermissionsListQuery();
  const [viewRole] = useLazyViewRoleQuery();
  const [addRole] = useAddRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const type = location?.state?.type;
  const id = location?.state?.id;

  const getPermissionsList = async () => {
    const res = await apiRequest(permissionsList);
    if (res?.status == "success") {
      setRoleList(res?.modules);
    }
    console.log(res);
  };

  const roleView = async (id) => {
    const res = await apiRequest(viewRole, id);
    if (res?.status == "success") {
      setName(res?.role_data?.name);
      setSelectedPermissions(res?.role_data?.permissions);
    }
    console.log(res);
  };

  const Add = async () => {
    if (!name) {
      setError(true);
      return;
    }
    setError(false);
    const formdata = new FormData();
    formdata.append("name", name);
    selectedPermissions?.map((item, index) => {
      formdata.append(`permissions[${index}]`, item);
    });
    if (type == "edit") {
      formdata.append("role_id", id);
    }
    setLoader(true);
    const res = await apiRequest(
      type == "edit" ? updateRole : addRole,
      formdata
    );
    setLoader(false);
    if (res?.status == "success") {
      toast.success(res?.message);
      navigate("/role");
    }
  };

  useEffect(() => {
    getPermissionsList();
    if (id) {
      roleView(id);
    }
  }, []);

  console.log(selectedPermissions);

  // Handle toggling of permissions
  const handlePermissionToggle = (roleId) => {
    let selectedlist = [];
    selectedPermissions.map((item) => {
      if (item != roleId) {
        selectedlist.push(item);
      }
    });
    if (!selectedPermissions?.includes(roleId)) {
      selectedlist.push(roleId);
    }
    setSelectedPermissions(selectedlist);
  };

  return (
    <div className="dashRightView home_section">
      <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
        {type == "edit" ? "Edit Role" : "Add Role"}
      </h2>
      <div className="input-pop p-3 mt-4">
        <h4 className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18  ">
          Role Name
        </h4>
        <input
          type="text"
          className="input-pop ps-2 col-md-3"
          placeholder=""
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        {error && !name && <p className="err">Enter Role Name</p>}
        <h4 className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
          Permission :
        </h4>
        <div className="d-flex flex-wrap mt-3">
          {roleList?.map((item, id) => {
            return (
              <div className="col-md-3 mt-3 ps-1">
                <p className="f3 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                  {item?.name}
                </p>

                <div className="d-flex ac-js">
                  <div>
                    {item?.permissions?.map((permission, index) => {
                      const isSelected = selectedPermissions?.includes(
                        permission.id
                      );
                      return (
                        <div className="d-flex ac-js gap-2 mb-2">
                          <img
                            src={isSelected ? toggle_on : toggle_off}
                            className="roles-icon pointer"
                            alt=""
                            onClick={() =>
                              handlePermissionToggle(permission.id)
                            }
                          />
                          <p className=" f1 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mb-0 ">
                            {permission?.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button
          onClick={() => {
            Add();
          }}
          className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "
        >
          {loader && (
            <div
              class="spinner-border spinner-border-sm "
              role="status"
              style={{ marginRight: "5px" }}
            />
          )}
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddRole;
