import React, { useState, useEffect } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  toggle_off,
  toggle_on,
  view_icon,
} from "../../assets/imgs";
import apiRequest from "../redux/api/apiRequest";
import { toast } from "react-toastify";
import {
  useListUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useLazyViewUserQuery,
  useLazyUserStatusQuery,
} from "../redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import { EMAIL } from "../redux/api/Constants";

const UsersList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(false);
  const [viewpopup, setViewPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState("");
  const [type, setType] = useState();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    phone: "",
    city: "",
    state: "",
    country: "",
  });

  const [listUser] = useListUserMutation();
  const [userStatus] = useLazyUserStatusQuery();
  const [viewUser] = useLazyViewUserQuery();
  const [addUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const getList = async () => {
    const formdata = new FormData();
    formdata.append("rows", 10);
    formdata.append("search", "");
    formdata.append("order", "asc");
    const res = await apiRequest(listUser, formdata);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const userView = async (id) => {
    setPopup(true);
    const res = await apiRequest(viewUser, id);
    if (res?.status == "success") {
      setUserData(res?.user_data);
    }
    console.log(res);
  };

  const Add = async () => {
    if (
      !userData?.name ||
      userData?.phone?.length < 10 ||
      !userData?.email ||
      !userData?.email.match(EMAIL)
    ) {
      setError(true);
      return;
    }
    if (type == "add") {
      if (!userData?.city || !userData?.state || !userData?.country) {
        setError(true);
        return;
      }
    }
    setError(false);
    const formdata = new FormData();
    formdata.append("name", userData?.name);
    formdata.append("email", userData?.email);
    formdata.append("phone", userData?.phone);
    if (type == "edit") {
      formdata.append("user_id", userData?.id);
    }

    if (type == "add") {
      formdata.append("city", userData?.city);
      formdata.append("state", userData?.state);
      formdata.append("country", userData?.country);
    }
    setLoader(true);
    const res = await apiRequest(
      type == "add" ? addUser : updateUser,
      formdata
    );
    setLoader(false);
    setPopup(false);
    if (res?.status == "success") {
      toast.success(res?.message);
    } else {
      toast.error("Error");
    }
    setUserData({});
    getList();
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStatus = async (id) => {
    const res = await apiRequest(userStatus, id);
    if (res?.status == "success") {
      toast.success(res?.message);
      getList();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: " Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Number",
        accessor: "phone",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "View Device Details",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                if (givenpermissions?.includes("CAN-View-USERS")) {
                  userView(row.original.id);
                  setType("view");
                }
              }}
              className="cust-btn "
            >
              <img src={view_icon} alt="" className="mx-2 edit-btn " />
            </button>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-USERS")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },

      // {
      //   Header: "Action",
      //   accessor: "status",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <React.Fragment>
      //         <button
      //           onClick={() => handleChange(row.original.id)}
      //           className={`border-0 toggle-btn cust-btn `}
      //         >
      //           {value ? (
      //             <div>
      //               <img className="w-50" src={toggle_on} />
      //             </div>
      //           ) : (
      //             <div>
      //               <img className="w-50" src={toggle_off} />
      //             </div>
      //           )}
      //         </button>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex ac-jc">
                {givenpermissions?.includes("CAN-Edit-USERS") && (
                  <button
                    onClick={() => {
                      userView(row.original.id);
                      setType("edit");
                    }}
                    className="cust-btn"
                  >
                    <img src={edit_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
                {/* <button
                                    onClick={() => setDeletePopup(true)}
                                    className="cust-btn "
                                >
                                    <img src={delete_icon} alt="" className="mx-2 edit-btn " />
                                </button> */}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="dashRightView home_section">
      <div className="d-flex ac-jb">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
          User List
        </h2>
        {givenpermissions?.includes("CAN-Add-USERS") && (
          <button
            onClick={() => {
              setPopup(true);
              setType("add");
            }}
            className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn "
          >
            {" "}
            + Add User
          </button>
        )}
      </div>
      {popup && (
        <div className="popup-design">
          <div className="popup">
            <div className="d-flex ac-jb">
              <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                User {type == "view" ? "View" : type == "add" ? "Add" : "Edit"}
              </h2>
              <button
                onClick={() => {
                  setPopup(false);
                  setUserData({});
                }}
                className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary cust-btn"
              >
                <CancelIcon />
              </button>
            </div>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Email
            </h4>
            <input
              type="email"
              className="input-pop ps-2 w-100"
              placeholder=" Email Address"
              name="email"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={userData?.email}
              disabled={type == "view" ? true : false}
            />
            {error && (!userData?.email || !userData?.email?.match(EMAIL)) && (
              <p className="err">Enter Valid Email</p>
            )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Name
            </h4>
            <input
              type="text"
              className="input-pop ps-2 w-100"
              placeholder=" Name"
              name="name"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={userData?.name}
              disabled={type == "view" ? true : false}
            />
            {error && !userData?.name && <p className="err">Enter Name</p>}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Phone Number
            </h4>
            <input
              type="number"
              className="input-pop ps-2 w-100"
              placeholder="Phone Number"
              name="phone"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  handleUpdate(e);
                }
              }}
              value={userData?.phone}
              disabled={type == "view" ? true : false}
            />
            {error && userData?.phone?.length < 10 && (
              <p className="err">Enter Valid Phone Number</p>
            )}
            {type == "add" && (
              <div>
                <div className="d-flex ac-jb ">
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      City
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-100"
                      placeholder="City"
                      name="city"
                      onChange={(e) => {
                        handleUpdate(e);
                      }}
                      value={userData?.city}
                      disabled={type == "view" ? true : false}
                    />
                    {error && !userData?.city && (
                      <p className="err">Enter City</p>
                    )}
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      State
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-100"
                      placeholder="State"
                      name="state"
                      onChange={(e) => {
                        handleUpdate(e);
                      }}
                      value={userData?.state}
                      disabled={type == "view" ? true : false}
                    />
                    {error && !userData?.state && (
                      <p className="err">Enter State</p>
                    )}
                  </div>
                </div>
                <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                  Country
                </h4>
                <input
                  type="text"
                  className="input-pop ps-2 w-100"
                  placeholder="Country"
                  name="country"
                  onChange={(e) => {
                    handleUpdate(e);
                  }}
                  value={userData?.country}
                  disabled={type == "view" ? true : false}
                />
                {error && !userData?.country && (
                  <p className="err">Enter Country</p>
                )}
              </div>
            )}
            {type == "view" && (
              <div>
                <div className="d-flex">
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      Device 1
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-90"
                      placeholder="Device"
                    />
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      Location
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-90"
                      placeholder="Location"
                    />
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      No of Members
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-80"
                      placeholder="15"
                    />
                  </div>
                </div>
                <div className="d-flex sm-flex-column">
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      Device 2
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-90"
                      placeholder="Device"
                    />
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      Location
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-90"
                      placeholder="Location"
                    />
                  </div>
                  <div>
                    <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                      No of Members
                    </h4>
                    <input
                      type="text"
                      className="input-pop ps-2 w-80"
                      placeholder="20"
                    />
                  </div>
                </div>
              </div>
            )}
            {type != "view" && (
              <div className="d-flex ac-jc mt-3">
                <button
                  onClick={() => {
                    Add();
                  }}
                  className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "
                >
                  {loader && (
                    <div
                      class="spinner-border spinner-border-sm "
                      role="status"
                      style={{ marginRight: "5px" }}
                    ></div>
                  )}
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <Table columns={columns} data={list} show={false} />
    </div>
  );
};

export default UsersList;
