import React, { useEffect, useState } from "react";
import {
  company_icon,
  email_icon,
  eye_icon,
  logo,
  mobile_icon,
  password_icon,
} from "../../assets/imgs";
import { useNavigate, useParams } from "react-router-dom";
import apiRequest from "../redux/api/apiRequest";
import { toast } from "react-toastify";
import {
  useChangePasswordMutation,
  useLazyVerifyTokenQuery,
} from "../redux/api/api";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyToken] = useLazyVerifyTokenQuery();
  const [changePassword] = useChangePasswordMutation();
  const { token } = useParams();
  console.log(token, "token");

  const checkToken = async (tok) => {
    const res = await apiRequest(verifyToken, tok);
    if (res?.status == "success") {
      setVerified(true);
    } else {
      setVerified(false);
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  useEffect(() => {
    if (token) {
      checkToken(token);
    }
  }, []);

  const resetPassword = async () => {
    if (!verified) {
      return;
    }
    if (
      confirmPassword.length < 8 ||
      password.length < 8 ||
      password != confirmPassword
    ) {
      setErr(true);
      return;
    }
    const formdata = new FormData();
    formdata.append("token", token);
    formdata.append("password", password);
    formdata.append("password_confirmation", confirmPassword);
    setErr(false);
    setLoader(true);
    const res = await apiRequest(changePassword, formdata);
    setLoader(false);
    if (res?.status == "success") {
      navigate("/");
      toast.success(res?.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
    console.log(res);
  };
  return (
    <div className="login">
      <div className="login-details ">
        <div className="login-box">
          <div className="logo-img mb-4">
            <img src={logo} className="logo" alt="" />
          </div>
          <div>
            <h2 className="f4 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22   mb-3">
              Reset Password
            </h2>
            <div className="input-box">
              <div className="input-field d-flex ac-jb">
                <span>
                  <img
                    src={password_icon}
                    className="input-icon"
                    alt="Password Icon"
                  />
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    maxLength={20}
                    className="cust-btn ps-2 w-90"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                </span>
                <img
                  src={eye_icon}
                  onClick={() => setVisible(!visible)}
                  className="eye-icon pointer"
                  alt="Toggle Visibility"
                />
              </div>
              {err && password.length < 8 && (
                <p className="err">Enter Minimum 8 Characters</p>
              )}
            </div>
            <div className="input-box">
              <div className="input-field d-flex ac-jb">
                <span>
                  <img
                    src={password_icon}
                    className="input-icon"
                    alt="Password Icon"
                  />
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    maxLength={20}
                    className="cust-btn ps-2 w-90"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    value={confirmPassword}
                  />
                </span>
                <img
                  src={eye_icon}
                  onClick={() => setVisible(!visible)}
                  className="eye-icon pointer"
                  alt="Toggle Visibility"
                />
              </div>
              {err &&
                (!confirmPassword ||
                  confirmPassword != password ||
                  confirmPassword.length < 8) && (
                  <p className="err">
                    {!confirmPassword
                      ? "Enter Confirm Password"
                      : confirmPassword.length < 8
                      ? "Enter Minimum 8 Characters"
                      : "Password Mismatch"}
                  </p>
                )}
            </div>
            <div className="d-flex ac-jc">
              <button
                className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mb-3 "
                onClick={() => {
                  resetPassword();
                }}
              >
                {loader && (
                  <div
                    class="spinner-border spinner-border-sm "
                    role="status"
                    style={{ marginRight: "5px" }}
                  ></div>
                )}
                Reset Via Email
              </button>
            </div>
            <p className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 text-center sec mb-3">
              Already have an account?{" "}
              <span
                className="f3 primary pointer"
                onClick={() => navigate("/")}
              >
                Sign in{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
