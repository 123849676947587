import React, { useState } from "react";
import {
  company_icon,
  email_icon,
  eye_icon,
  logo,
  mobile_icon,
  password_icon,
} from "../../assets/imgs";
import { useNavigate } from "react-router-dom";
import { EMAIL } from "../redux/api/Constants";
import apiRequest from "../redux/api/apiRequest";
import { toast } from "react-toastify";
import { useForgotPasswordMutation } from "../redux/api/api";

const Registration = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();

  const resetPassword = async () => {
    if (!email || !email?.match(EMAIL)) {
      setErr(true);
      return;
    }
    const formdata = new FormData();
    formdata.append("email", email);
    setErr(false);
    setLoader(true);
    const res = await apiRequest(forgotPassword, formdata);
    setLoader(false);
    if (res?.status == "success") {
      navigate("/");
      toast.success(res?.message);
    }
    console.log(res);
  };
  return (
    <div className="login">
      <div className="login-details ">
        <div className="login-box">
          <div className="logo-img mb-4">
            <img src={logo} className="logo" alt="" />
          </div>
          <div>
            <h2 className="f4 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22   mb-3">
              Forgot Password
            </h2>
            <div className="input-field mb-4">
              <img src={email_icon} className="email-icon" alt="" />
              <input
                type="email"
                className="cust-btn ps-2 w-90"
                placeholder=" Email Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
            {err && <p className="err">Please enter Valid Email</p>}
            <div className="d-flex ac-jc">
              <button
                className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mb-3 "
                onClick={() => {
                  resetPassword();
                }}
              >
                {loader && (
                  <div
                    class="spinner-border spinner-border-sm "
                    role="status"
                    style={{ marginRight: "5px" }}
                  ></div>
                )}
                Reset Via Email
              </button>
            </div>
            <p className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 text-center sec mb-3">
              Already have an account?{" "}
              <span
                className="f3 primary pointer"
                onClick={() => navigate("/")}
              >
                Sign in{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
