export const BASE_URL =
  "https://testing-sirenlaravel.ninositsolution.com/public/crm/"; // live
// "http://192.168.1.58/singapore-siren/public/crm/";

export const URL = {
  //AUTHENTICATION
  LOGIN: "admin-login ",
  LOGOUT: "admin-logout",
  FORGOT_PASSWORD: "admin/forgot-password",
  TOKEN_VERIFY: "admin-token-verify/",
  CHANGE_PASSWORD: "admin-set-password",

  //ADMIN

  ADD_ADMIN: "add-admin",
  VIEW_ADMIN: "view-admin/",
  ADMIN_STATUS: "change-admin-status/",
  UPDATE_ADMIN: "update-admin",
  LIST_ADMIN: "admin-lists",
  DELETE_ADMIN: "delete-admin/",

  //ROLE

  PERMISSIONS_LIST: "admin/role/module-lists",
  ROLE_LIST: "admin/role/lists",
  ADD_ROLE: "add-role",
  UPDATE_ROLE: "update-role",
  DELETE_ROLE: "delete-role/",
  VIEW_ROLE: "view-role/",
  ROLE_STATUS: "change-role-status/",

  // USER LIST
  USER_LIST: "user-lists",
  ADD_USER: "add-user",
  UPDATE_USER: "update-user",
  VIEW_USER: "view-user/",
  USER_STATUS: "change-user-status/",

  // DEVICE LIST
  DEVICE_LIST: "device-lists",
  DEVICE_ADD: "add-device",
  DEVICE_VIEW: "view-device/",
  DEVICE_UPDATE: "update-device",
  DEVICE_STATUS: "change-device-status/",

  //LOCATION
  LOCATION_LIST: "location-lists",
  LOCATION_STATUS: "change-location-status/",
};

export const TOKEN = "token";
export const LOGGEDUSER = "loggeduser";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";
export const LOCATION = "userlocation";
export const LOCATIONNAME = "locationname";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => {
  return EMAIL.test(email);
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
