import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  toggle_off,
  toggle_on,
} from "../../assets/imgs";
import { useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import apiRequest from "../redux/api/apiRequest";
import {
  useAddAdminMutation,
  useLazyAdminStatusQuery,
  useLazyDeleteAdminQuery,
  useLazyViewAdminQuery,
  useListAdminMutation,
  useListRoleMutation,
  useUpdateAdminMutation,
} from "../redux/api/api";
import { EMAIL } from "../redux/api/Constants";
import { toast } from "react-toastify";

const UserList = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [deletePopup, setDeletePopup] = useState("");
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState("");
  const [visible, setVisible] = useState(false);
  const [rolesList, setRoleslist] = useState([]);
  const [admindetails, setAdminDetails] = useState({
    id: "",
    name: "",
    email: "",
    role_id: "",
    mobile: "",
    password: "",
    password_confirmation: "",
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(true);
  const [addAdmin] = useAddAdminMutation();
  const [viewAdmin] = useLazyViewAdminQuery();
  const [listAdmin] = useListAdminMutation();
  const [adminStatus] = useLazyAdminStatusQuery();
  const [deleteAdmin] = useLazyDeleteAdminQuery();
  const [updateAdmin] = useUpdateAdminMutation();
  const [listRole] = useListRoleMutation();

  const handleStatus = async (id) => {
    const res = await apiRequest(adminStatus, id);
    if (res?.status == "success") {
      toast.success(res?.message);
      getList();
    }
  };

  const handleDelete = async () => {
    const res = await apiRequest(deleteAdmin, deletePopup);
    if (res?.status == "success") {
      toast.success(res?.message);
      setDeletePopup("");
      getList();
    }
  };

  const AdminView = async (id) => {
    const res = await apiRequest(viewAdmin, id);
    if (res?.status == "success") {
      let data = res?.admin_data;
      Object.keys(admindetails).forEach((item) => {
        console.log(item);
        setAdminDetails((prevState) => ({
          ...prevState,
          [item]:
            item == "name"
              ? data?.name
              : item == "email"
              ? data?.email
              : item == "role_id"
              ? data?.role?.id
              : item == "mobile"
              ? data?.mobile
              : item == "id"
              ? data?.id
              : "",
        }));
      });
      setEditMode(true);
      setPopup(true);
    }
    console.log(res);
  };

  const getList = async () => {
    const formdata = new FormData();
    formdata.append("rows", 10);
    formdata.append("search", "");
    formdata.append("order", "asc");
    const res = await apiRequest(listAdmin, formdata);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
    }
  };

  const getRolesList = async () => {
    const formdata = new FormData();
    formdata.append("rows", 10);
    formdata.append("search", "");
    formdata.append("order", "asc");
    const res = await apiRequest(listRole, formdata);
    if (res?.status == "success") {
      setRoleslist(res?.lists);
    }
  };
  useEffect(() => {
    getList();
    getRolesList();
  }, []);

  const AdminAdd = async () => {
    if (
      !admindetails?.name ||
      admindetails?.mobile.length < 10 ||
      !admindetails?.email ||
      !admindetails?.role_id ||
      !admindetails?.email.match(EMAIL)
    ) {
      setError(true);
      return;
    }
    if (!editMode) {
      if (
        admindetails?.password.length < 8 ||
        admindetails?.password_confirmation.length < 8 ||
        admindetails?.password != admindetails?.password_confirmation
      ) {
        setError(true);
        return;
      }
    }
    setError(false);
    const formdata = new FormData();
    formdata.append("name", admindetails?.name);
    formdata.append("email", admindetails?.email);
    formdata.append("role_id", admindetails?.role_id);
    formdata.append("mobile", admindetails?.mobile);
    formdata.append("password", admindetails?.password);
    formdata.append(
      "password_confirmation",
      admindetails?.password_confirmation
    );
    if (editMode) {
      formdata.append("admin_id", admindetails?.id);
    }
    setLoader(true);
    const res = await apiRequest(editMode ? updateAdmin : addAdmin, formdata);
    setLoader(false);
    setPopup(false);
    setEditMode(false);
    if (res?.status == "success") {
      toast.success(res?.message);
    } else {
      toast.error("Error");
    }
    getList();
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setAdminDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: " Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Email",
        accessor: "email",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Role",
        accessor: "role.name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-ADMINS")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },

      // {
      //   Header: "Action",
      //   accessor: "status",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <React.Fragment>
      //         <button
      //           onClick={() => handleChange(row.original.id)}
      //           className={`border-0 toggle-btn cust-btn `}
      //         >
      //           {value ? (
      //             <div>
      //               <img className="w-50" src={toggle_on} />
      //             </div>
      //           ) : (
      //             <div>
      //               <img className="w-50" src={toggle_off} />
      //             </div>
      //           )}
      //         </button>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex ac-jc">
                {givenpermissions?.includes("CAN-Edit-ADMINS") && (
                  <button
                    onClick={() => {
                      AdminView(row.original.id);
                    }}
                    className="cust-btn "
                  >
                    <img src={edit_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
                {givenpermissions?.includes("CAN-Delete-ADMINS") && (
                  <button
                    onClick={() => setDeletePopup(row.original.id)}
                    className="cust-btn "
                  >
                    <img src={delete_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="dashRightView home_section">
      <div className="d-flex ac-jb">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
          Admin List
        </h2>
        {givenpermissions?.includes("CAN-Add-ADMINS") && (
          <button
            onClick={() => setPopup(true)}
            className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn "
          >
            {" "}
            + Add Admin
          </button>
        )}
      </div>
      {popup && (
        <div className="popup-design">
          <div className="popup">
            <div className="d-flex ac-jb">
              <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                {editMode ? "Edit Admin" : "Add Admin"}
              </h2>
              <button
                onClick={() => {
                  if (editMode) {
                    setEditMode(false);
                  }
                  setPopup(false);
                }}
                className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary cust-btn"
              >
                <CancelIcon />
              </button>
            </div>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Name
            </h4>
            <input
              type="text"
              className="input-pop ps-2 w-100"
              placeholder="Name"
              name="name"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={admindetails?.name}
            />
            {error && !admindetails?.name && (
              <p className="err">Enter Your Name</p>
            )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Phone Number
            </h4>
            <input
              type="number"
              className="input-pop ps-2 w-100"
              placeholder="Phone Number"
              name="mobile"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  handleUpdate(e);
                }
              }}
              value={admindetails?.mobile}
            />
            {error && admindetails?.mobile.length < 10 && (
              <p className="err">Enter Valid Phone Number</p>
            )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Email
            </h4>
            <input
              type="email"
              className="input-pop ps-2 w-100"
              placeholder=" Email Address"
              name="email"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={admindetails?.email}
            />
            {error &&
              (!admindetails?.email || !admindetails?.email.match(EMAIL)) && (
                <p className="err">Enter Valid Email</p>
              )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Roles
            </h4>
            <select
              className="input-pop ps-2 w-100"
              name="role_id"
              onChange={(e) => {
                handleUpdate(e);
              }}
              value={admindetails?.role_id}
            >
              <option value={""}> -- Select --</option>
              {rolesList?.map((item) => {
                return <option value={item?.id}> {item?.name}</option>;
              })}
            </select>
            {error && !admindetails?.role_id && (
              <p className="err">Select Your Role</p>
            )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Password
            </h4>
            <div className="input-pop mb-2 w-100 d-flex ac-jb">
              <input
                type={visible ? "text" : "password"}
                maxLength={20}
                className="cust-btn  w-90"
                placeholder="Password"
                name="password"
                onChange={(e) => {
                  handleUpdate(e);
                }}
                value={admindetails?.password}
              />
              <img
                src={eye_icon}
                onClick={() => setVisible(!visible)}
                className="eye-icon pointer"
                alt=""
              />
            </div>
            {error && !editMode && admindetails?.password.length < 8 && (
              <p className="err">
                {!admindetails?.password
                  ? "Enter Your password"
                  : "Enter Minimum 8 Charactors"}
              </p>
            )}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Confirm Password
            </h4>
            <div className="input-pop mb-2 w-100 d-flex ac-jb">
              <input
                type={visible ? "text" : "password"}
                maxLength={20}
                className="cust-btn w-90"
                placeholder=" Confirm Password"
                name="password_confirmation"
                onChange={(e) => {
                  handleUpdate(e);
                }}
                value={admindetails?.password_confirmation}
              />
              <img
                src={eye_icon}
                onClick={() => setVisible(!visible)}
                className="eye-icon pointer"
                alt=""
              />
            </div>
            {error &&
              !editMode &&
              (admindetails?.password_confirmation.length < 8 ||
                admindetails?.password !=
                  admindetails?.password_confirmation) && (
                <p className="err">
                  {!admindetails?.password_confirmation
                    ? "Enter Confirm Password"
                    : admindetails?.password_confirmation.length < 8
                    ? "Enter Minimum 8 Charactors"
                    : "Password Mismatch"}
                </p>
              )}
            <div className="d-flex ac-jc">
              <button
                onClick={() => {
                  AdminAdd();
                }}
                className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "
              >
                {loader && (
                  <div
                    class="spinner-border spinner-border-sm "
                    role="status"
                    style={{ marginRight: "5px" }}
                  ></div>
                )}
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {deletePopup && (
        <div className="img-popup-design">
          <div className="img-popup">
            <p className="f3 ms-2 mt-1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-4">
              Are you sure, want to Delete?
            </p>
            <div className="d-flex ac-jb px-2 mt-5">
              <button onClick={() => setDeletePopup("")} className="no-btn">
                No
              </button>
              <button
                onClick={() => {
                  handleDelete();
                }}
                className="yes-btn f2"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <Table columns={columns} data={list} show={false} />
    </div>
  );
};

export default UserList;
