import React from 'react'
import Topbar from '../Topbar/Topbar'
import ProfileNavBar from '../ProfileNavbar'
import { Outlet } from 'react-router-dom'

const Layout = () => {
    return (
        <div className="">
            <Topbar />
            <div className='dashboard'>
                <ProfileNavBar />
                <Outlet />
            </div>
        </div>
    )
}

export default Layout