import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  device_act,
  device_icon,
  location_act,
  location_icon,
  logo,
  logout_icon,
  user_act,
  user_icon,
} from "../assets/imgs";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import apiRequest from "../screens/redux/api/apiRequest";
import { useLazyLogoutQuery } from "../screens/redux/api/api";
import { toast } from "react-toastify";

const ProfileNavBar = ({ setSaidList }) => {
  // All Dropdowns
  const [roles, setRoles] = useState(null);
  const [saideBarShow, setSaidBarShow] = useState(true);
  const [locationlist, setLocationlist] = useState([]);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [btn, setBtn] = useState(false);
  const [activeTabs, setActiveTabs] = useState(0);
  const [vip, setVip] = useState(null);
  const [parking, setParking] = useState(null);
  const [suspect, setSuspect] = useState(null);
  const [popup, setPopup] = useState();
  const [warehouse, setWarehouse] = useState(null);
  const [trespassing, setTrespassing] = useState(null);
  const [billing, setBilling] = useState(null);
  const [logout] = useLazyLogoutQuery();

  const handleLogout = async () => {
    const res = await apiRequest(logout);
    if (res?.status == "success") {
      localStorage.clear();
      navigate("/");
      toast.success(res?.message);
    }
    console.log(res);
    setPopup(false);
  };

  const toggle_nav = () => {
    setSaidBarShow(!saideBarShow);
  };

  return (
    <div className={`${saideBarShow ? " sidebar" : "close_sidebar  "} `}>
      <div
        onClick={() => {
          toggle_nav();
        }}
        className=""
      >
        {saideBarShow ? (
          <KeyboardDoubleArrowRightIcon className="arrow-right" />
        ) : (
          ""
        )}
      </div>
      <div
        onClick={() => {
          toggle_nav();
        }}
        className=""
      >
        {saideBarShow ? (
          ""
        ) : (
          <KeyboardDoubleArrowLeftIcon className="arrow-left" />
        )}
      </div>

      <div className="menu_bar overflow-x-hidden overflow-scroll">
        <div className="menu">
          <ul className="menu_links ps-0">
            {givenpermissions?.includes("CAN-List-ADMINS") && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/admin-list");
                }}
              >
                <h4
                  className={` ${
                    splitLocation[1] === "admin-list" ||
                    splitLocation[1] === "add-user-list"
                      ? " f3 active_bar"
                      : " "
                  } ps-4  fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                >
                  {splitLocation[1] === "admin-list" ||
                  splitLocation[1] === "add-user-list" ? (
                    <img src={user_act} className="icon " alt="" />
                  ) : (
                    <img src={user_icon} className="icon " alt="" />
                  )}
                  <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ps-1">
                    Admin List
                  </span>
                </h4>
              </li>
            )}
            {givenpermissions?.includes("CAN-List-ROLES") && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/role");
                }}
              >
                <h4
                  className={` ${
                    splitLocation[1] === "role" ||
                    splitLocation[1] === "add-role"
                      ? " f3 active_bar"
                      : " "
                  } ps-4  fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                >
                  {splitLocation[1] === "role" ||
                  splitLocation[1] === "add-role" ? (
                    <img src={user_act} className="icon " alt="" />
                  ) : (
                    <img src={user_icon} className="icon" alt="" />
                  )}
                  <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ps-1">
                    Role
                  </span>
                </h4>
              </li>
            )}
            {givenpermissions?.includes("CAN-List-USERS") && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/user-list");
                }}
              >
                <h4
                  className={` ${
                    splitLocation[1] === "user-list" ||
                    splitLocation[1] === "add-user-list"
                      ? " f3 active_bar"
                      : " "
                  } ps-4  fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                >
                  {splitLocation[1] === "user-list" ||
                  splitLocation[1] === "add-user-list" ? (
                    <img src={user_act} className="icon " alt="" />
                  ) : (
                    <img src={user_icon} className="icon" alt="" />
                  )}
                  <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ps-1">
                    User List
                  </span>
                </h4>
              </li>
            )}
            {givenpermissions?.includes("CAN-List-DEVICES") && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/device-list");
                }}
              >
                <h4
                  className={` ${
                    splitLocation[1] === "device-add" ||
                    splitLocation[1] === "device-list"
                      ? " f3 active_bar"
                      : " "
                  } ps-4  fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                >
                  {splitLocation[1] === "device-add" ||
                  splitLocation[1] === "device-list" ? (
                    <img src={device_act} className="icon " alt="" />
                  ) : (
                    <img src={device_icon} className="icon" alt="" />
                  )}
                  <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ps-1">
                    Device List
                  </span>
                </h4>
              </li>
            )}
            {givenpermissions?.includes("CAN-List-LOCATIONS") && (
              <li
                className="nav_link trans"
                onClick={() => {
                  navigate("/location-marks");
                }}
              >
                <h4
                  className={` ${
                    splitLocation[1] === "location-marks" ||
                    splitLocation[1] === "location-marks-add"
                      ? " f3 active_bar"
                      : " "
                  } ps-4  fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                >
                  {splitLocation[1] === "location-marks" ||
                  splitLocation[1] === "location-marks-add" ? (
                    <img src={location_act} className="icon " alt="" />
                  ) : (
                    <img src={location_icon} className="icon" alt="" />
                  )}
                  <span className="text text-nowrap f2 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 ps-1">
                    Location Marks
                  </span>
                </h4>
              </li>
            )}
            {/* {billing !== null && (
              <div className=" nav-dropdown">
                <li
                  className="nav_link text-center trans sidebar-drop"
                  onClick={() => {
                    navigate("/billing-counter");
                    setBilling(1);
                  }}
                >
                  <p
                    className={` ${
                      (activeTabs == 0 && billing == 1) ||
                      splitLocation[1] === "billing-counter" ||
                      splitLocation[1] === "add-billing-counter"
                        ? " active_bar_drop f3"
                        : " green1  "
                    } trans m-0  f2 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                  >
                    Billing Master
                  </p>
                </li>
                <li
                  className="nav_link text-center trans sidebar-drop"
                  onClick={() => {
                    navigate("/billing-counter-list");
                    setBilling(2);
                  }}
                >
                  <p
                    className={` ${
                      (activeTabs == 0 && billing == 2) ||
                      splitLocation[1] === "billing-counter-list"
                        ? "active_bar_drop f3"
                        : "green1 "
                    } trans m-0  f2 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 `}
                  >
                    Billing List
                  </p>
                </li>
              </div>
            )} */}
          </ul>
        </div>
      </div>
      <div
        className="d-flex ac-je pt-2 pe-2 pointer"
        onClick={() => setPopup(true)}
      >
        <img src={logout_icon} className="icon " alt="" />
        <h4 className=" f2 fs-xs-10 trans fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18 mb-0">
          Logout
        </h4>
      </div>
      {popup && (
        <div className="img-popup-design">
          <div className="img-popup">
            <h2 className="f4 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary mt-4">
              Logout
            </h2>
            <p className="f3 ms-2 mt-1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-4">
              Are you sure, want to Logout?
            </p>
            <div className="d-flex ac-jb px-2 mt-5">
              <button onClick={() => setPopup(false)} className="no-btn">
                No
              </button>
              <button
                onClick={() => {
                  handleLogout();
                }}
                className="yes-btn f2"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileNavBar;
