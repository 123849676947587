export const logo = require("../imgs/logo.png");
export const profile = require("../imgs/profile-icon.png");
export const user_icon = require("../imgs/admin-icon.png");
export const user_act = require("../imgs/admin-act.png");
export const device_icon = require("../imgs/device-icon.png");
export const device_act = require("../imgs/device-act.png");
export const location_icon = require("../imgs/location-icon.png");
export const location_act = require("../imgs/location-act.png");
export const toggle_on = require("../imgs/toggle-on.png");
export const toggle_off = require("../imgs/toggle-off.png");
export const logout_icon = require("../imgs/logout-icon.png");
export const company_icon = require("../imgs/company-icon.png");
export const mobile_icon = require("../imgs/mobile-icon.png");
export const email_icon = require("../imgs/email-icon.png");
export const password_icon = require("../imgs/password-icon.png");
export const delete_icon = require("../imgs/delete-icon.png");
export const edit_icon = require("../imgs/edit-icon.png");
export const eye_icon = require("../imgs/eye-icon.png");
export const view_icon = require("../imgs/view-icon.png");