import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  toggle_off,
  toggle_on,
  view_icon,
} from "../../assets/imgs";
import { useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import apiRequest from "../redux/api/apiRequest";
import {
  useLazyLocationListQuery,
  useLazyLocationStatusQuery,
} from "../redux/api/api";
import { toast } from "react-toastify";

const LocationMarks = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [list, setList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [locationList] = useLazyLocationListQuery();
  const [locationStatus] = useLazyLocationStatusQuery();

  const getList = async () => {
    const res = await apiRequest(locationList);
    console.log(res);
    if (res?.status == "success") {
      setList(res?.data);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleStatus = async (id) => {
    const res = await apiRequest(locationStatus, id);
    if (res?.status == "success") {
      toast.success(res?.message);
      getList();
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Location Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-LOCATIONS")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },
      // {
      //     Header: "Action",
      //     accessor: "",
      //     Cell: ({ value, row }) => {
      //         return (
      //             <React.Fragment>
      //                 <div className="d-flex ac-jc">
      //                     <button
      //                         onClick={() => setPopup(true)}
      //                         className="cust-btn "
      //                     >
      //                         <img src={edit_icon} alt="" className=" edit-btn " />
      //                     </button>

      //                 </div>
      //             </React.Fragment>
      //         );
      //     },
      // },
    ],
    []
  );

  return (
    <div className="dashRightView home_section">
      {/* <div className='d-flex ac-jb'> */}
      <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
        Location Marks
      </h2>
      {/* <button onClick={() => setPopup(true)} className='f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn '> + Add Location </button> */}
      {/* </div> */}
      {popup && (
        <div className="popup-design">
          <div className="popup">
            <div className="d-flex ac-jb">
              <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                Location Mark
              </h2>
              <button
                onClick={() => setPopup(false)}
                className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary cust-btn"
              >
                <CancelIcon />
              </button>
            </div>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Location Name
            </h4>
            <input
              type="text"
              className="input-pop ps-2 w-100"
              placeholder=" Name"
            />
            <div className="d-flex ac-jc mt-3">
              <button
                onClick={() => setPopup(false)}
                className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "
              >
                {/* {loader && (
                  <div
                    class="spinner-border spinner-border-sm "
                    role="status"
                    style={{ marginRight: "5px" }}
                  ></div>
                )} */}
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <Table columns={columns} data={list} show={false} />
    </div>
  );
};

export default LocationMarks;
