import React from 'react'
import { useNavigate } from 'react-router-dom'

const DeviceAdd = () => {
    const navigate = useNavigate();
    return (
        <div className="dashRightView home_section" >
            <div className='d-flex ac-jb'>
                <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                    Device List
                </h2>
                <button onClick={() => navigate("/device-list")} className='f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn '> + Add </button>
            </div>
            <div className='input-pop col-md-4 p-3 mt-4'>
                <h4 className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18  ">
                    Device Name
                </h4>
                <input type="text" className="input-pop ps-2 w-100 " placeholder="" />
                <h4 className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18  mt-3">
                    Device ID
                </h4>
                <input type="text" className="input-pop ps-2 w-100" placeholder="" />
                <div className='d-flex ac-jc'>
                    <button onClick={() => navigate("/device-list")} className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "  >
                        {/* {loader && (
                <div
                  class="spinner-border spinner-border-sm "
                  role="status"
                  style={{ marginRight: "5px" }}
                ></div>
              )} */}
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeviceAdd