import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  eye_icon,
  password_icon,
  toggle_off,
  toggle_on,
  view_icon,
} from "../../assets/imgs";
import { useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  useAddDeviceMutation,
  useDeviceListMutation,
  useLazyDeviceStatusQuery,
  useLazyViewDeviceQuery,
  useUpdateDeviceMutation,
} from "../redux/api/api";
import apiRequest from "../redux/api/apiRequest";
import { toast } from "react-toastify";

const DeviceList = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState("");
  const [loader, setLoader] = useState(false);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [assigned, setAssigned] = useState("");
  const [id, setId] = useState("");
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);

  const [DeviceStatus] = useLazyDeviceStatusQuery();
  const [deviceList] = useDeviceListMutation();
  const [addDevice] = useAddDeviceMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const [viewDevice] = useLazyViewDeviceQuery();

  const [type, setType] = useState("");

  const handleStatus = async (id) => {
    const res = await apiRequest(DeviceStatus, id);
    if (res?.status == "success") {
      toast.success(res?.message);
      getList();
    }
  };

  const getList = async () => {
    const formdata = new FormData();
    formdata.append("rows", 10);
    formdata.append("search", "");
    formdata.append("order", "asc");
    const res = await apiRequest(deviceList, formdata);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const Add = async () => {
    if (!deviceId || !deviceName) {
      setError(true);
      return;
    }
    if (type == "edit" && !assigned) {
      setError(true);
      return;
    }
    setError(false);
    const formdata = new FormData();
    formdata.append("name", deviceName);
    formdata.append("device_code", deviceId);
    if (type == "edit") {
      formdata.append("device_id", id);
      formdata.append("user_id", assigned);
    }
    setLoader(true);
    const res = await apiRequest(
      type == "edit" ? updateDevice : addDevice,
      formdata
    );
    setLoader(false);
    setPopup(false);
    if (res?.status == "success") {
      toast.success(res?.message);
      setType("");
    } else {
      toast.error("Error");
    }
    getList();
  };

  const View = async (id) => {
    setType("edit");
    setDeviceId("");
    setDeviceName("");
    setAssigned("");
    setId(id);
    const res = await apiRequest(viewDevice, id);
    if (res?.status == "success") {
      let data = res?.role_data;
      setDeviceName(data?.device_name);
      setDeviceId(data?.device_code);
      setAssigned(data?.assigned);
      setUsers(res?.primary_users);
      setPopup(true);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Device Name",
        accessor: "device_name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: " Device ID",
        accessor: "device_code",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Assigned",
        accessor: "assigned.name",
        Cell: ({ value, row }) => {
          return <p>{value ? value : "--"}</p>;
        },
      },
      {
        Header: "Device Status",
        accessor: "device_status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button className={`border-0 toggle-btn cust-btn `}>
                {value == 1 ? (
                  <div>
                    <FavoriteIcon className="green" />
                  </div>
                ) : (
                  <div>
                    <FavoriteIcon className="red" />
                  </div>
                )}
              </button>
            </div>
          );
        },
      },
      {
        Header: " Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-DEVICES")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex ac-jc">
                {givenpermissions?.includes("CAN-Edit-DEVICES") && (
                  <button
                    type="edit"
                    onClick={() => {
                      View(row.original.id);
                    }}
                    className="cust-btn "
                  >
                    <img src={edit_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="dashRightView home_section">
      <div className="d-flex ac-jb">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
          Device List
        </h2>
        {givenpermissions?.includes("CAN-Add-DEVICES") && (
          <button
            onClick={() => {
              setPopup(true);
              setDeviceId("");
              setDeviceName("");
              setAssigned("");
              setType("add");
            }}
            className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn "
          >
            {" "}
            + Add Device{" "}
          </button>
        )}
      </div>
      {popup && (
        <div className="popup-design">
          <div className="popup">
            <div className="d-flex ac-jb">
              <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
                {type == "edit" ? "Edit Device" : "Add Device"}
              </h2>
              <button
                onClick={() => {
                  setPopup(false);
                  setType("");
                }}
                className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 primary cust-btn"
              >
                <CancelIcon />
              </button>
            </div>
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Device Name
            </h4>
            <input
              type="text"
              className="input-pop ps-2 w-100"
              placeholder=" Name"
              onChange={(e) => {
                setDeviceName(e.target.value);
              }}
              value={deviceName}
            />
            {error && !deviceName && <p className="err">Enter Device Name</p>}
            <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
              Device ID
            </h4>
            <input
              type="text"
              className="input-pop ps-2 w-100"
              placeholder="Device ID"
              onChange={(e) => {
                setDeviceId(e.target.value);
              }}
              value={deviceId}
            />
            {error && !deviceId && <p className="err">Enter Device Id</p>}
            {type == "edit" && (
              <div>
                <h4 className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mt-3 ">
                  Assigned To
                </h4>
                <select
                  name=""
                  id=""
                  className="input-pop ps-2 w-100"
                  onChange={(e) => {
                    setAssigned(e.target.value);
                  }}
                  value={assigned}
                >
                  <option value="">-- Select --</option>
                  {users?.map((item) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })}
                </select>
                {error && !assigned && <p className="err">Assign The User</p>}
              </div>
            )}
            <div className="d-flex ac-jc mt-3">
              <button
                onClick={() => {
                  Add();
                }}
                className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mt-3 "
              >
                {loader && (
                  <div
                    class="spinner-border spinner-border-sm "
                    role="status"
                    style={{ marginRight: "5px" }}
                  ></div>
                )}
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <Table columns={columns} data={list} show={false} />
    </div>
  );
};

export default DeviceList;
