// @ts-nocheck
import React, { useEffect, useState } from "react";
import { email_icon, eye_icon, logo, password_icon } from "../../assets/imgs";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../redux/api/Constants";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../redux/api/api";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();

  const [loginApi] = useLoginMutation();
  const [formState, setFormState] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);

  const { username, password } = formState;
  const { emailErr, passErr } = errors;

  const validatePassword = (password) => {
    return password.length >= 4;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
    if (name === "username") {
      setErrors((prev) => ({
        ...prev,
        emailErr: !isValidEmail(value) ? "Please enter a valid Email" : "",
      }));
    }
    if (name === "password") {
      setErrors((prev) => ({
        ...prev,
        passErr: !validatePassword(value)
          ? "Password must be at least 4 characters"
          : "",
      }));
    }
  };

  const handleKey = (event) => {
    if (event.key == "Enter" && formState.password && formState.username) {
      login();
    }
  };
  const login = () => {
    const emailValid = isValidEmail(username);
    const passwordValid = validatePassword(password);

    if (!emailValid || !passwordValid) {
      setErrors({
        emailErr: !emailValid ? "Please enter a valid Email" : "",
        passErr: !passwordValid ? "Password must be at least 4 characters" : "",
      });
      return;
    }

    let fdata = new FormData();
    fdata.append("email", username);
    fdata.append("password", password);

    setLoader(true);
    loginApi(fdata)
      .unwrap()
      .then((res) => {
        // console.log("Login Successful", res?.permissions);
        if (res?.status == "success") {
          let permission = res?.permissions;
          let screen = permission?.includes("CAN-List-ADMINS")
            ? "/admin-list"
            : permission?.includes("CAN-List-ROLES")
            ? "/role"
            : permission?.includes("CAN-List-USERS")
            ? "/user-list"
            : permission?.includes("CAN-List-DEVICES")
            ? "/device-list"
            : permission?.includes("CAN-List-LOCATIONS")
            ? "/location-marks"
            : "No List Permissions";
          if (screen == "No List Permissions") {
            toast.info(screen);
          } else {
            navigate(screen);
          }
          toast.success(res?.message);
          localStorage.setItem("token", res?.token);
          localStorage.setItem("user_details", JSON.stringify(res?.admin));
          localStorage.setItem(
            "user_permission",
            JSON.stringify(res?.permissions)
          );
        } else {
          // console.log("check");
        }
      })
      .catch((err) => {
        console.error("Login Failed", err);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (localStorage?.getItem("token")) {
      navigate("/admin-list");
    }
  }, []);

  return (
    <div className="login" onKeyDown={handleKey}>
      <div className="login-details">
        <h2 className="f4 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 white text-center mb-4">
          Welcome to Login Form
        </h2>
        <div className="login-box">
          <div className="logo-img mb-4">
            <img src={logo} className="logo" alt="Logo" />
          </div>
          <div className="input-box mb-2">
            <div className="input-field d-flex ac-jb">
              <img src={email_icon} className="email-icon" alt="Email Icon" />
              <input
                type="email"
                name="username"
                className="cust-btn ps-2 w-90"
                placeholder="Email Address"
                autoComplete="off"
                value={username}
                onChange={handleInputChange}
              />
            </div>
            {emailErr && <p className="err">{emailErr}</p>}
          </div>
          <div className="input-box">
            <div className="input-field d-flex ac-jb">
              <span>
                <img
                  src={password_icon}
                  className="input-icon"
                  alt="Password Icon"
                />
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  maxLength={20}
                  className="cust-btn ps-2 w-90"
                  placeholder="Password"
                  autoComplete="off"
                  value={password}
                  onChange={handleInputChange}
                />
              </span>
              <img
                src={eye_icon}
                onClick={() => setVisible(!visible)}
                className="eye-icon pointer"
                alt="Toggle Visibility"
              />
            </div>
            {passErr && <p className="err">{passErr}</p>}
          </div>

          <div
            onClick={() => navigate("/registration")}
            className="d-flex ac-je"
          >
            <button className="cust-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mb-3 red">
              Forget Password
            </button>
          </div>
          <div className="d-flex ac-jc">
            <button
              onClick={login}
              className="login-btn f2 fs-sm-10 fs-md-10 fs-lg-10 fs-xl-12 fs-xxl-14 mb-0"
            >
              {loader && (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                  style={{ marginRight: "5px" }}
                ></div>
              )}
              SignIn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
