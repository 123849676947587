const apiRequest = async (url, payload, ID) => {
  try {
    const res = await url(ID ? { payload, ID } : payload).unwrap();
    return res;
  } catch (error) {
    console.error(error);
    return {
      error: error.error,
      status: error.status,
      originalStatus: error.originalStatus,
    };
  }
  //   return url(payload)
  //     .unwrap()
  //     .then(res => res)
  //     .catch(error => error);
};

export default apiRequest;
