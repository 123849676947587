import React, { useEffect, useState } from "react";
import { logo, profile } from "../../assets/imgs";
import { useLazyViewAdminQuery } from "../../screens/redux/api/api";
import apiRequest from "../../screens/redux/api/apiRequest";

const Topbar = () => {
  const [saideBarShow, setSaidBarShow] = useState(true);
  const userdata = JSON.parse(localStorage.getItem("user_details"));
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [viewAdmin] = useLazyViewAdminQuery();

  const getUserdata = async (id) => {
    const res = await apiRequest(viewAdmin, id);
    if (res?.status == "success") {
      // givenpermissions?.length != res?.permission
    }
    console.log(res);
  };

  useEffect(() => {
    if (userdata?.id) {
      // getUserdata(userdata?.id);
    }
  }, []);

  return (
    <div className="d-flex ac-jb topbar">
      <div className="d-flex ac-jc">
        <div className="image_text pointer mt-1 me-2">
          <img src={logo} className="nav-logo" alt="" />
        </div>
        <div>
          <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 border-bot  ">
            SIM LIAN GROUP LIMITED
          </h2>
          <div className="d-flex">
            <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 border-right pe-3 mb-0">
              Creating Space
            </p>
            <p className="f3 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ps-3 mb-0">
              Creating Home
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex ac-jc">
        <img src={profile} className="profile-img" alt="" />
        <div>
          <h2 className="f3  fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 text-nowrap">
            Welcome: {userdata?.name}
          </h2>
          <p className="f3 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 mb-0 text-nowrap">
            Role :
            <span className="f1 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18">
              {userdata?.role?.name}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
