import { Row, Col, Pagination, Button, Form } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
// import { toast } from "react-toastify";
import { GlobalFilter } from "./GlobalFilter";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";

export function Table({ columns, data, setPageno, pagecount, show }) {
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    setPagenumber(pageIndex + 1);
  }, [pageIndex]);

  return (
    <div className="mt-4 table-design">
      {/* <Row className="">
        <Col className="">
          <div className="d-flex ac-je ps-2 mt-2">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
        </Col>
      </Row> */}
      <BTable
        striped
        size="sm"
        responsive
        {...getTableProps()}
        className=" table-border table"
      //  variant="dark"
      >
        <thead className="">
          {headerGroups.map((headerGroup) => (
            <tr className="nowrap " {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  className="px-3 text-center py-2 bg-primar "
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <h4 className="f2 white fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 pt-3">
                    {column.render("Header")}
                  </h4>
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="text-center f2" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="fs-sm-10 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 pt-3 mt-2"
                      t-2
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {show && pagecount && (
        <Row className="justify-content-between table-show my-2 px-4 w-100">
          <Col>
            <Pagination className="gap-1 pointer">
              <button
                className="page-left"
                onClick={() => {
                  setPageno(
                    pagecount?.current_page == 1
                      ? pagecount?.current_page
                      : pagecount?.current_page - 1
                  );
                }}
              >
                <KeyboardArrowLeftIcon className="page-left-arrow" />
              </button>
              <input
                type="number"
                className="form-control ml-2  p-1 py-1 text-center"
                value={pagecount?.current_page}
                onChange={(e) => {
                  setPageno(
                    e.target.value > pagecount.last_page
                      ? pagecount?.last_page
                      : e.target.value
                  );
                }}
              />
              <button
                className="page-left"
                onClick={() => {
                  setPageno(
                    pagecount?.current_page == pagecount?.last_page
                      ? pagecount?.last_page
                      : pagecount?.current_page + 1
                  );
                }}
              >
                <KeyboardArrowRightIcon className="page-left-arrow" />
              </button>
            </Pagination>
          </Col>
          <Col>
            <span className="d-flex align-items-center justify-content-end">
              <p className="px-1 mb-0">No.Of.Pages</p>
              <button className=" f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 form-control   text-center">
                {pagecount?.last_page}
              </button>
            </span>
          </Col>
        </Row>
      )}
      {show && !pagecount && (
        <Row className="justify-content-between table-show my-2 px-4 w-100">
          <Col>
            <Pagination className="gap-1 pointer">
              <Pagination.Prev
                onClick={() => previousPage()}
              // disabled={!canPreviousPage}
              />
              {/* <button className=" f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 form-control   text-center">1</button> */}
              <input
                type="number"
                className="form-control ml-2  p-1 py-1 text-center"
                value={pagenumber}
                onChange={(e) => {
                  if (!Number(e.target.value)) {
                    setPagenumber(e.target.value);
                    return;
                  }
                  if (e.target.value > pageCount) {
                    gotoPage(pageCount - 1);
                    setPagenumber(pageCount);
                    return;
                  }
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                  setPagenumber(e.target.value);
                }}
              />

              <Pagination.Next
                onClick={() => nextPage()}
              // disabled={!canNextPage}
              />
            </Pagination>
          </Col>
          <Col>
            <span className="d-flex align-items-center justify-content-end">
              <p className="px-1 mb-0">No.Of.Pages</p>
              <button className=" f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 form-control   text-center">
                {pageCount}
              </button>
            </span>
          </Col>
          {/* <Col>
          <span className="d-flex align-items-center justify-content-end">
            <input
              type="number"
              className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 form-control  text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
            <p className="px-1 mb-0">Page</p>{" "}
          </span>
        </Col> */}
        </Row>
      )}
    </div>
  );
}
