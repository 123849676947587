import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table";
import {
  delete_icon,
  edit_icon,
  toggle_off,
  toggle_on,
} from "../../assets/imgs";
import { useNavigate } from "react-router-dom";
import { admin_list } from "../redux/api/dummyJson";
import CancelIcon from "@mui/icons-material/Cancel";
import apiRequest from "../redux/api/apiRequest";
import {
  useLazyDeleteRoleQuery,
  useLazyRoleStatusQuery,
  useListRoleMutation,
} from "../redux/api/api";
import { toast } from "react-toastify";

const Role = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState("");
  const [list, setList] = useState([]);
  const givenpermissions = JSON.parse(localStorage.getItem("user_permission"));
  const [pagination, setPagination] = useState("");
  const [listRole] = useListRoleMutation();
  const [deleteRole] = useLazyDeleteRoleQuery();
  const [roleStatus] = useLazyRoleStatusQuery();

  const handleStatus = async (id) => {
    const res = await apiRequest(roleStatus, id);
    if (res?.status == "success") {
      toast.success(res?.message);
      getList();
    }
  };

  const handleDelete = async () => {
    const res = await apiRequest(deleteRole, deletePopup);
    if (res?.status == "success") {
      toast.success(res?.message);
      setDeletePopup("");
      getList();
    }
  };

  const getList = async () => {
    const formdata = new FormData();
    formdata.append("rows", 10);
    formdata.append("search", "");
    formdata.append("order", "asc");
    const res = await apiRequest(listRole, formdata);
    if (res?.status == "success") {
      setList(res?.lists);
      setPagination(res?.pagination_meta);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Role",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <div>
              <button
                onClick={() => {
                  if (givenpermissions?.includes("CAN-Status-ROLES")) {
                    handleStatus(row.original.id);
                  }
                }}
                className={`border-0 ms-2 cust-btn `}
              >
                {value == 1 ? (
                  <img className="toggle-btn" src={toggle_on} />
                ) : (
                  <img className="toggle-btn" src={toggle_off} />
                )}
              </button>
            </div>
          );
        },
      },

      {
        Header: "Action",
        accessor: "",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex ac-jc">
                {givenpermissions?.includes("CAN-Edit-ROLES") && (
                  <button
                    onClick={() =>
                      navigate("/add-role", {
                        state: { type: "edit", id: row.original.id },
                      })
                    }
                    className="cust-btn "
                  >
                    <img src={edit_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
                {givenpermissions?.includes("CAN-Delete-ROLES") && (
                  <button
                    onClick={() => setDeletePopup(row.original.id)}
                    className="cust-btn "
                  >
                    <img src={delete_icon} alt="" className="mx-2 edit-btn " />
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="dashRightView home_section">
      <div className="d-flex ac-jb">
        <h2 className="f3 fs-sm-12 fs-md-16 fs-lg-18 fs-xl-20 fs-xxl-22 primary ">
          Roles List
        </h2>
        {givenpermissions?.includes("CAN-Add-ROLES") && (
          <button
            onClick={() => navigate("/add-role")}
            className="f2 fs-sm-10 fs-md-12 fs-lg-14 fs-xl-16 fs-xxl-18 white add-btn "
          >
            {" "}
            + Add Roles
          </button>
        )}
      </div>
      {deletePopup && (
        <div className="img-popup-design">
          <div className="img-popup">
            <p className="f3 ms-2 mt-1 ms-md-2 ms-lg-2 ms-xl-2 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mt-4">
              Are you sure, want to Delete?
            </p>
            <div className="d-flex ac-jb px-2 mt-5">
              <button onClick={() => setDeletePopup("")} className="no-btn">
                No
              </button>
              <button
                onClick={() => {
                  handleDelete();
                }}
                className="yes-btn f2"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <Table columns={columns} data={list} show={false} />
    </div>
  );
};

export default Role;
